import { Table as RadixTable } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type TableProps = RadixTable.RootProps;

const TableV2Root = ({ ...props }: TableProps) => {
  return <RadixTable.Root {...props} />;
};

const TableV2Header = forwardRef<HTMLDivElement, RadixTable.HeaderProps>(({ ...props }) => {
  return <RadixTable.Header {...props} />;
});

const TableV2Row = forwardRef<HTMLDivElement, RadixTable.RowProps>(({ ...props }) => {
  return <RadixTable.Row {...props} />;
});

const TableV2ColumnHeaderCell = forwardRef<HTMLDivElement, RadixTable.ColumnHeaderCellProps>(({ ...props }) => {
  return <RadixTable.ColumnHeaderCell {...props} />;
});

const TableV2Body = forwardRef<HTMLDivElement, RadixTable.BodyProps>(({ ...props }) => {
  return <RadixTable.Body {...props} />;
});

const TableV2Cell = forwardRef<HTMLDivElement, RadixTable.CellProps>(({ ...props }) => {
  return <RadixTable.Cell {...props} />;
});

const TableV2RowHeaderCell = forwardRef<HTMLDivElement, RadixTable.RowHeaderCellProps>(({ ...props }) => {
  return <RadixTable.RowHeaderCell {...props} />;
});

export {
  TableV2Root as Root,
  TableV2Header as Header,
  TableV2Row as Row,
  TableV2ColumnHeaderCell as ColumnHeaderCell,
  TableV2Body as Body,
  TableV2Cell as Cell,
  TableV2RowHeaderCell as RowHeaderCell,
};
