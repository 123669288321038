import React, { forwardRef } from "react";
import {
  IconButton as RadixIconButton,
  IconButtonProps as RadixIconButtonProps,
} from "@radix-ui/themes";

type IconButtonProps = RadixIconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ ...props }, forwardRef) => {
    return <RadixIconButton ref={forwardRef} {...props} />;
  }
);
