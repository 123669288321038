import React, { forwardRef } from "react";
import { ValidatedForm } from "remix-validated-form";

type FormProps = React.ComponentPropsWithoutRef<typeof ValidatedForm>;
export const Form: React.ForwardRefExoticComponent<FormProps & React.RefAttributes<HTMLFormElement>> = forwardRef<
  HTMLFormElement,
  FormProps
>((props, forwardedRef) => {
  return <ValidatedForm {...props} ref={forwardedRef} />;
});
