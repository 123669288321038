import { Switch as RadixSwitch, SwitchProps as RadixSwitchProps } from "@radix-ui/themes";
import React from "react";
import { forwardRef } from "react";
import { Flex } from "../Flex/Flex";
import { Text } from "../Text/Text";

type SwitchProps = RadixSwitchProps & {
  label?: string;
  labelPosition?: "top" | "left";
};

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(({ label, labelPosition, ...props }, forwardedRef) => {
  const component = <RadixSwitch ref={forwardedRef} {...props} />;
  if (label) {
    return (
      <label>
        <Flex direction={labelPosition === "top" ? "column" : "row"}>
          <Text as="div" size={props.size} weight="bold">
            {label}
          </Text>
          {component}
        </Flex>
      </label>
    );
  }
});
