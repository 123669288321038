import { Button } from "@radix-ui/themes";
import React from "react";
import { DayProps, useDayRender } from "react-day-picker";

export function Day({ ...props }: DayProps) {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { isHidden, isButton, buttonProps, divProps, activeModifiers } = useDayRender(
    props.date,
    props.displayMonth,
    buttonRef,
  );

  if (isHidden) return <></>;
  if (isButton)
    return (
      <Button ref={buttonRef} variant={activeModifiers.selected ? "solid" : "ghost"} m="unset" {...buttonProps}>
        {props.date.getDate()}
      </Button>
    );
  return <div {...divProps}>{props.date.getDate()}</div>;
}
