import React, { forwardRef } from "react";
import {
  Grid as RadixGrid,
  GridProps as RadixGridProps,
} from "@radix-ui/themes";

type GridProps = RadixGridProps;

export const Grid = forwardRef<HTMLDivElement, GridProps>(
  ({ ...props }, forwardRef) => {
    return <RadixGrid ref={forwardRef} {...props} />;
  }
);
