import { DropdownMenu } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type DropdownProps = DropdownMenu.RootProps;

export function Dropdown({ ...props }: DropdownProps) {
  return <DropdownMenu.Root {...props} />;
}

Dropdown.Trigger = DropdownMenu.Trigger;

const DropdownContent = forwardRef<HTMLDivElement, DropdownMenu.ContentProps>(
  ({ ...props }, ref) => {
    return <DropdownMenu.Content variant="soft" {...props} ref={ref} />;
  }
);

Dropdown.Content = DropdownContent;
Dropdown.Item = DropdownMenu.Item;
Dropdown.Sub = DropdownMenu.Sub;
Dropdown.SubContent = DropdownMenu.SubContent;
Dropdown.SubTrigger = DropdownMenu.SubTrigger;
Dropdown.TriggerIcon = DropdownMenu.TriggerIcon;
Dropdown.Separator = DropdownMenu.Separator;
