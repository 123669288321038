import {
  Button as RadixButton,
  ButtonProps as RadixButtonProps,
  Spinner,
} from "@radix-ui/themes";
import React, { forwardRef } from "react";

interface ButtonProps extends RadixButtonProps {
  loading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ loading, leftIcon, rightIcon, size = "2", ...props }, forwardedRef) => {
    return (
      <RadixButton size={size} {...props} ref={forwardedRef}>
        <>
          {leftIcon}
          {props.children}
          {loading ? <Spinner /> : rightIcon}
        </>
      </RadixButton>
    );
  }
);
