import React, { forwardRef } from "react";
import {
  Flex as RadixFlex,
  FlexProps as RadixFlexProps,
} from "@radix-ui/themes";

type FlexProps = RadixFlexProps;

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ gap = "2", ...props }, forwardRef) => {
    return <RadixFlex gap={gap} {...props} ref={forwardRef} />;
  }
);
