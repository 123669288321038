import React, { forwardRef } from "react";
import { Callout as RadixCallout } from "@radix-ui/themes";

type CalloutProps = RadixCallout.RootProps;

const CalloutRoot = forwardRef<HTMLDivElement, CalloutProps>(
  ({ ...props }, forwardedRef) => {
    return <RadixCallout.Root {...props} ref={forwardedRef} />;
  }
);
CalloutRoot.displayName = "Callout.Root";

type CalloutIconProps = RadixCallout.IconProps;

const CalloutIcon = forwardRef<HTMLDivElement, CalloutIconProps>(
  ({ ...props }, forwardedRef) => {
    return <RadixCallout.Icon {...props} ref={forwardedRef} />;
  }
);
CalloutIcon.displayName = "Callout.Icon";

type CalloutTextProps = RadixCallout.TextProps;

const CalloutText = forwardRef<HTMLDivElement, CalloutTextProps>(
  ({ ...props }, forwardedRef) => {
    return <RadixCallout.Text {...props} ref={forwardedRef} />;
  }
);
CalloutText.displayName = "Callout.Text";

export { CalloutRoot as Root, CalloutIcon as Icon, CalloutText as Text };
