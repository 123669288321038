import { Select as RadixSelect } from "@radix-ui/themes";
import React, { forwardRef, useId } from "react";
import { useField } from "remix-validated-form";
import { Text } from "../Text/Text";

type SelectProps = RadixSelect.RootProps & {
  options: string[];
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  className?: string;
  style?: React.CSSProperties;
  position?: RadixSelect.ContentProps["position"];
  variant?: RadixSelect.TriggerProps["variant"];
  multi?: boolean;
  formId?: string;
};

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      options,
      onChange,
      label,
      placeholder,
      defaultValue,
      className,
      size = "2",
      position = "popper",
      style,
      variant,
      name,
      formId,
      ...props
    },
    forwardedRef,
  ) => {
    const randId = useId();
    const fieldName = name || randId;
    const compFormId = formId || (name ? undefined : `${randId}-form`);
    const { error, getInputProps } = useField(fieldName, {
      formId: compFormId,
    });
    const inputProps = getInputProps();
    const component = (
      <RadixSelect.Root
        size={size}
        defaultValue={defaultValue}
        onValueChange={(value) => {
          inputProps.onChange?.(value);
          onChange?.(value);
        }}
        {...inputProps}
        {...props}
      >
        <RadixSelect.Trigger
          className={className}
          variant={variant}
          placeholder={placeholder}
          style={style}
          ref={forwardedRef}
        />
        <RadixSelect.Content variant="soft" position={position}>
          {options.map((option) => (
            <RadixSelect.Item key={option} value={option}>
              {option}
            </RadixSelect.Item>
          ))}
        </RadixSelect.Content>
      </RadixSelect.Root>
    );
    if (label) {
      return (
        <label style={style}>
          <Text as="div" size="2" mb="1" weight="bold">
            {label}
          </Text>
          {component}
          {error ? (
            <Text as="div" size="1" color="red" mt="1">
              {error}
            </Text>
          ) : null}
        </label>
      );
    }
    return component;
  },
);
