/* eslint-disable react/prop-types */
import { FC, ForwardedRef, HTMLAttributes, ReactNode } from "react";
import React, { forwardRef } from "react";
import "./Button.css";

import type { Sparta } from "../../../../src/interfaces/Sparta";
import { guardSpartaName } from "../../../../src/util/ErrorHandling";

import { Section } from "../Section/Section";
import { Spinner } from "../Spinner/Spinner";
import { Text } from "../Text/Text";

export interface IButton extends HTMLAttributes<HTMLButtonElement>, Sparta<"Button"> {
  /** Required ReactNode that needs to exist between component tags */
  children: ReactNode;
  /** defines the type of button to be rendered */
  variant?: "solid" | "outline" | "none";
  /** callback function to be called when there is a method click */
  onClick?: () => void;
  /** disables button */
  disabled?: boolean;
  /** Allows use of references */
  ref?: ForwardedRef<HTMLButtonElement>;
  /** Determines the type of button */
  type?: "button" | "submit" | "reset";
  /** Determines whether the button is loading or not */
  loading?: boolean;
}

/**
 * Button component for the Sparta Component Library
 *
 * @return Button component
 */
export const Button: FC<IButton> = forwardRef(function Button(
  { children, className = "", theme = "primary", variant = "solid", loading, disabled, ...props }: IButton,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  guardSpartaName(props, "Button");

  return (
    <button
      {...props}
      aria-busy={loading}
      disabled={disabled || loading}
      className={`sparta ${variant} ${theme} ${className}`}
      ref={ref}
    >
      {!loading ? (
        <Text ignoreTheme>{children}</Text>
      ) : (
        <Section center>
          <Spinner size="1rem" loading innerColor="#edeff1" />
        </Section>
      )}
    </button>
  );
});

Button.defaultProps = { "data-sparta": "Button" };
