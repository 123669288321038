import { Avatar as RadixAvatar, AvatarProps as RadixAvatarProps } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type AvatarProps = RadixAvatarProps;

export const Avatar = forwardRef<HTMLImageElement, AvatarProps>(({ radius = "full", ...props }, forwardedRef) => {
  let abbreviatedFallback = props.fallback;
  if (typeof props.fallback === "string") {
    abbreviatedFallback = props.fallback
      .split(" ")
      .map((word) => word[0]?.toUpperCase())
      .join("");
  }

  props.fallback = abbreviatedFallback;
  return <RadixAvatar radius={radius} {...props} ref={forwardedRef} />;
});
