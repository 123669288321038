import { Dialog } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type DialogProps = Dialog.RootProps;

const ModalRoot = ({ ...props }: DialogProps) => {
  return <Dialog.Root {...props} />;
};

const ModalContent = forwardRef<HTMLDivElement, Dialog.ContentProps>(({ ...props }, forwardedRef) => {
  return <Dialog.Content {...props} ref={forwardedRef} />;
});

const ModalTrigger = Dialog.Trigger;
const ModalClose = forwardRef<HTMLButtonElement, Dialog.CloseProps>(({ ...props }, forwardedRef) => {
  return <Dialog.Close {...props} ref={forwardedRef} />;
});

const ModalTitle = Dialog.Title;
const ModalDescription = Dialog.Description;

export {
  ModalRoot as Root,
  ModalContent as Content,
  ModalTrigger as Trigger,
  ModalClose as Close,
  ModalTitle as Title,
  ModalDescription as Description,
};
