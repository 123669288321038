import { Tabs } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type TabsProps = Tabs.RootProps;

const TabsRoot = ({ ...props }: TabsProps) => {
  return <Tabs.Root {...props} />;
};

const TabsList = forwardRef<HTMLDivElement, Tabs.ListProps>(({ ...props }, forwardedRef) => {
  return <Tabs.List {...props} ref={forwardedRef} />;
});

const TabsTrigger = Tabs.Trigger;
const TabsContent = forwardRef<HTMLDivElement, Tabs.ContentProps>(({ ...props }, forwardedRef) => {
  return <Tabs.Content {...props} ref={forwardedRef} />;
});

export { TabsRoot as Root, TabsList as List, TabsTrigger as Trigger, TabsContent as Content };
