import { TextArea as RadixTextArea, TextAreaProps as RadixTextAreaProps } from "@radix-ui/themes";
import React, { forwardRef } from "react";
import { Text } from "../Text/Text";

type TextAreaProps = RadixTextAreaProps & {
  label?: string;
  hint?: string;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, hint, ...props }, forwardedRef) => {
  const component = <RadixTextArea {...props} ref={forwardedRef} />;

  if (label) {
    return (
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {label}
        </Text>
        {component}
        {hint && (
          <Text as="div" size="1" color="gray" mt="1">
            {hint}
          </Text>
        )}
      </label>
    );
  }
  return component;
});
