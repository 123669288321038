import { ScrollArea as RadixScrollArea, ScrollAreaProps as RadixScrollAreaProps } from "@radix-ui/themes";
import React, { forwardRef } from "react";

export const ScrollArea = forwardRef<HTMLDivElement, RadixScrollAreaProps>(({ children, ...props }, ref) => {
  return (
    <RadixScrollArea {...props} ref={ref}>
      {children}
    </RadixScrollArea>
  );
});
