import { Popover as RadixPopover } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type RadixPopoverProps = RadixPopover.RootProps;

const PopoverRoot = ({ ...props }: RadixPopoverProps) => {
  return <RadixPopover.Root {...props} />;
};

const PopoverContent = forwardRef<HTMLDivElement, RadixPopover.ContentProps>(({ ...props }, forwardedRef) => {
  return <RadixPopover.Content {...props} ref={forwardedRef} />;
});

const PopoverTrigger = RadixPopover.Trigger;
const PopoverClose = forwardRef<HTMLButtonElement, RadixPopover.CloseProps>(({ ...props }, forwardedRef) => {
  return <RadixPopover.Close {...props} ref={forwardedRef} />;
});

export { PopoverRoot as Root, PopoverContent as Content, PopoverTrigger as Trigger, PopoverClose as Close };
