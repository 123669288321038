import React, { forwardRef } from "react";
import { Box as RadixBox, BoxProps as RadixBoxProps } from "@radix-ui/themes";

type BoxProps = RadixBoxProps;

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  ({ ...props }, forwardRef) => {
    return <RadixBox {...props} ref={forwardRef} />;
  }
);
