import {
  Flex,
  Checkbox as RadixCheckbox,
  CheckboxProps as RadixCheckboxProps,
} from "@radix-ui/themes";
import React, { forwardRef } from "react";
import { Text } from "../Text/Text";

interface CheckboxProps extends RadixCheckboxProps {
  label?: string;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ size = "2", label, ...props }, forwardedRef) => {
    const component = (
      <RadixCheckbox size={size} ref={forwardedRef} {...props} />
    );
    if (label) {
      return (
        <Text as="label">
          <Flex gap="2">
            {component}
            {label}
          </Flex>
        </Text>
      );
    }
    return component;
  }
);
