import * as SliderPrimitive from "@radix-ui/react-slider";
import React, { FC, forwardRef } from "react";
import { Sparta } from "../../../interfaces/Sparta";
import { guardSpartaName } from "../../../util/ErrorHandling";
import "./Slider.css";

interface ISlider extends SliderPrimitive.SliderProps, Sparta<"Slider"> {}

export const Slider: FC<ISlider> = forwardRef<HTMLSpanElement, ISlider>(
  ({ "data-sparta": dataSparta = "Select", theme = "primary", ...props }, forwardedRef) => {
    guardSpartaName({ "data-sparta": dataSparta }, "Select");
    const value = props.value || props.defaultValue;

    return (
      <SliderPrimitive.Slider className={`sparta SliderRoot ${theme}`} {...props} ref={forwardedRef}>
        <SliderPrimitive.Track className="SliderTrack">
          <SliderPrimitive.Range className="SliderRange" />
        </SliderPrimitive.Track>
        {value?.map((_, i) => (
          <SliderPrimitive.Thumb key={i} className="SliderThumb" />
        ))}
      </SliderPrimitive.Slider>
    );
  },
);
