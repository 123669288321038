import { HoverCard as RadixHoverCard } from "@radix-ui/themes";
import React, { forwardRef } from "react";

// Define a root component for the HoverCard, wrapping RadixHoverCard.Root
type HoverCardRootProps = RadixHoverCard.RootProps;
const HoverCardRoot = ({ ...props }: HoverCardRootProps) => {
  return <RadixHoverCard.Root {...props} />;
};

// Define the trigger component, forwarding the ref
const HoverCardTrigger = forwardRef<HTMLAnchorElement, RadixHoverCard.TriggerProps>(({ ...props }, ref) => {
  return <RadixHoverCard.Trigger {...props} ref={ref} />;
});

// Define the content component, forwarding the ref and accepting custom styles
const HoverCardContent = forwardRef<HTMLDivElement, RadixHoverCard.ContentProps>((props, ref) => {
  return <RadixHoverCard.Content {...props} ref={ref} />;
});

// Exporting components with aliases for a consistent API
export { HoverCardRoot as Root, HoverCardTrigger as Trigger, HoverCardContent as Content };
