import React, { forwardRef } from "react";
import {
  Section as RadixSection,
  SectionProps as RadixSectionProps,
} from "@radix-ui/themes";

type SectionProps = RadixSectionProps;

export const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ ...props }, forwardRef) => {
    return <RadixSection ref={forwardRef} {...props} />;
  }
);
